import * as React from 'react';
import { Carousel } from './Carousel';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

type Article = {
  title: string;
  url: string;
};

type Image = {
  id: number;
  name: string;
  url: string;
};

type Slide = {
  id: number;
  title: string;
  description: string;
  image: Image;
  article?: Article;
};

interface SlideshowProps {
  height: number;
  infinite: boolean;
  showCaptions: boolean;
  showTicker: boolean;
  slides: Slide[];
  summarizedTicker: boolean;
  width: number;
}

const Slideshow: React.FC<SlideshowProps> = ({
  height,
  infinite,
  showCaptions,
  showTicker,
  slides,
  summarizedTicker,
  width,
}) => {
  return (
    <Carousel
      width={width}
      showTicker={showTicker}
      summarizedTicker={summarizedTicker}
      infinite={infinite}
    >
      {slides.map((slide) => (
        <div key={slide.id} className="slide">
          <div
            className="slide-graphic relative"
            style={{
              height: height,
              backgroundImage: `url(${slide.image?.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center top',
            }}
          >
            <div className="slide-content">
              <h3 className="mb-2">
                {slide.article ? (
                  <a
                    href={slide.article.url}
                    dangerouslySetInnerHTML={{ __html: slide.title }}
                  ></a>
                ) : (
                  <span
                    dangerouslySetInnerHTML={{ __html: slide.title }}
                  ></span>
                )}
              </h3>
              <div className="w-full space-y-2">
                <div
                  dangerouslySetInnerHTML={{ __html: slide.description }}
                ></div>
                {showCaptions && (
                  <p className="image-caption">
                    <a href={`/images/${slide.image?.id}`} target="_blank">
                      Learn more about this image
                    </a>
                    <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Slideshow;
