import * as React from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

export interface SearchProps {
  path: string;
}

interface Props extends SearchProps {
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
}

const Search: React.FC<Props> = (props) => {
  const { isFocused, path, onFocus, onBlur } = props;

  const inputWidth = isFocused ? 'w-full sm:w-4/5' : 'w-full sm:w-4/5';
  const placeholder = isFocused
    ? 'Type your search and press Enter...'
    : 'Search';

  return (
    <div className="relative flex-1 self-center mt-2 sm:mt-0 ml-2 sm:ml-0">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon className="h-5 w-5 text-slate-400" />
      </div>
      <form method="get" action={path}>
        <input
          type="search"
          name="q"
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          className={`${inputWidth} transition-all block pl-10 pr-3 py-3 border border-slate-700 rounded-full leading-5 placeholder-slate-400 bg-slate-700 focus:bg-white focus:border-white focus:ring-white focus:ring-shadow-0 text-gray-900`}
        />
      </form>
      {isFocused && (
        <button
          onClick={onBlur}
          className="mobile-search-close sm:search-close transition-all absolute inset-y-0 right-0 pr-3 flex items-center"
        >
          <XMarkIcon className="h-6 w-6 text-slate-400 hover:text-black" />
        </button>
      )}
    </div>
  );
};

export default Search;
