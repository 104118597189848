import * as React from 'react';
import { Video } from '../../../../interfaces/models';
import { Entity } from '../EntityResults/Result';
import Table from '../../Table';
import { TrashIcon } from '@heroicons/react/24/outline';

interface RelatedVideosProps {
  videos: Video[];
  onRemove: (entity: Entity) => void;
}

const RelatedVideos: React.FC<RelatedVideosProps> = ({ videos, onRemove }) => {
  const onRemoveVideo = (
    e: React.MouseEvent<HTMLButtonElement>,
    video: Video
  ): void => {
    e.preventDefault();
    onRemove(video);
  };

  if (videos.length > 0) {
    return (
      <>
        <h3 className="medium-title flex justify-between items-center">
          <span>Videos</span>
          <span>
            <a href={`${window.location.pathname}/sort/Video`} className="btn">
              Sort Videos &rarr;
            </a>
          </span>
        </h3>
        <Table headings={['ID', 'Name', 'Actions']}>
          {videos.map((video) => (
            <tr
              key={`${video.__typename}-${video.id}`}
              className="hover:bg-gray-50"
            >
              <td className="w-1 px-6 py-2 whitespace-nowrap">{video.id}</td>
              <td className="px-6 py-2 whitespace-nowrap">{video.name}</td>
              <td className="w-2 px-6 py-2 whitespace-nowrap">
                <button
                  className="btn py-1"
                  onClick={(e) => onRemoveVideo(e, video)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </td>
            </tr>
          ))}
        </Table>
      </>
    );
  } else {
    return null;
  }
};

export default RelatedVideos;
