import * as React from 'react';
import { useEffect, useState } from 'react';
import { fetchJSON } from '../../../utilities';
import EntitySearchInput from './EntitySearchInput';
import EntitySelect, { EntityType, EntityMap } from './EntitySelect';
import EntityResults from './EntityResults';
import Loading from '../../Loading';
import ErrorMessage from '../../ErrorMessage';
import { Entity } from './EntityResults/Result';
import {
  Article,
  Audio,
  Author,
  Document,
  Exhibit,
  Image,
  KeyDate,
  Keyword,
  Quiz,
  Slideshow,
  Video,
} from '../../../interfaces/models';
import {
  RelatedArticles,
  RelatedAudios,
  RelatedAuthors,
  RelatedDocuments,
  RelatedExhibits,
  RelatedImages,
  RelatedKeyDates,
  RelatedKeywords,
  RelatedQuizzes,
  RelatedSlideshows,
  RelatedVideos,
} from './Related';
import { MIN_CHARACTER_INPUT } from './EntitySearchInput';

interface EntitySearchProps {
  entityMap: EntityMap[];
  articles?: Article[];
  audios?: Audio[];
  authors?: Author[];
  documents?: Document[];
  exhibits?: Exhibit[];
  images?: Image[];
  keyDates?: KeyDate[];
  keywords?: Keyword[];
  quizzes?: Quiz[];
  slideshows?: Slideshow[];
  videos?: Video[];
}

const EntitySearch: React.FC<EntitySearchProps> = (props) => {
  const DEFAULT_ENTITY_INDEX = 0;

  const { entityMap } = props;
  const [selectedEntity, setSelectedEntity] = useState(
    entityMap[DEFAULT_ENTITY_INDEX]
  );
  const [results, setResults] = useState<Entity[]>([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [articles, setArticles] = useState<Article[]>(props.articles || []);
  const [audios, setAudios] = useState<Audio[]>(props.audios || []);
  const [authors, setAuthors] = useState<Author[]>(props.authors || []);
  const [documents, setDocuments] = useState<Document[]>(props.documents || []);
  const [exhibits, setExhibits] = useState<Exhibit[]>(props.exhibits || []);
  const [images, setImages] = useState<Image[]>(props.images || []);
  const [keyDates, setKeyDates] = useState<KeyDate[]>(props.keyDates || []);
  const [keywords, setKeywords] = useState<Keyword[]>(props.keywords || []);
  const [quizzes, setQuizzes] = useState<Quiz[]>(props.quizzes || []);
  const [slideshows, setSlideshows] = useState<Slideshow[]>(
    props.slideshows || []
  );
  const [videos, setVideos] = useState<Video[]>(props.videos || []);

  const [debouncedQuery, setDebouncedQuery] = useState('');
  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDebouncedQuery(e.target.value);
  };
  const handleClearResults = (): void => {
    setDebouncedQuery('');
  };

  const handleEntitySelect = (
    value: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setSelectedEntity(entityMap[value.target.selectedIndex]);
  };

  const handleAddEntity = (entity: Entity): void => {
    switch (entity.__typename) {
      case EntityType.Article:
        setArticles((prevArticles) => [...prevArticles, entity as Article]);
        break;
      case EntityType.Audio:
        setAudios((prevAudios) => [...prevAudios, entity as Audio]);
        break;
      case EntityType.Author:
        setAuthors((prevAuthors) => [...prevAuthors, entity as Author]);
        break;
      case EntityType.Document:
        setDocuments((prevDocuments) => [...prevDocuments, entity as Document]);
        break;
      case EntityType.Exhibit:
        setExhibits((prevExhibits) => [...prevExhibits, entity as Exhibit]);
        break;
      case EntityType.Image:
        setImages((prevImages) => [...prevImages, entity as Image]);
        break;
      case EntityType.KeyDate:
        setKeyDates((prevKeyDates) => [...prevKeyDates, entity as KeyDate]);
        break;
      case EntityType.Keyword:
        setKeywords((prevKeywords) => [...prevKeywords, entity as Keyword]);
        break;
      case EntityType.Quiz:
        setQuizzes((prevQuizzes) => [...prevQuizzes, entity as Quiz]);
        break;
      case EntityType.Slideshow:
        setSlideshows((prevSlideshows) => [
          ...prevSlideshows,
          entity as Slideshow,
        ]);
        break;
      case EntityType.Video:
        setVideos((prevVideos) => [...prevVideos, entity as Video]);
        break;
    }
  };

  const removeEntityFromCollection = (
    entity: Entity,
    collection: Entity[]
  ): Entity[] => {
    return collection.filter((item) => item.id !== entity.id);
  };

  const handleRemoveEntity = (entity: Entity): void => {
    switch (entity.__typename) {
      case EntityType.Article:
        setArticles(removeEntityFromCollection(entity, articles) as Article[]);
        break;
      case EntityType.Audio:
        setAudios(removeEntityFromCollection(entity, audios) as Audio[]);
        break;
      case EntityType.Author:
        setAuthors(removeEntityFromCollection(entity, authors) as Author[]);
        break;
      case EntityType.Document:
        setDocuments(
          removeEntityFromCollection(entity, documents) as Document[]
        );
        break;
      case EntityType.Exhibit:
        setExhibits(removeEntityFromCollection(entity, exhibits) as Exhibit[]);
        break;
      case EntityType.Image:
        setImages(removeEntityFromCollection(entity, images) as Image[]);
        break;
      case EntityType.KeyDate:
        setKeyDates(removeEntityFromCollection(entity, keyDates) as KeyDate[]);
        break;
      case EntityType.Keyword:
        setKeywords(removeEntityFromCollection(entity, keywords) as Keyword[]);
        break;
      case EntityType.Quiz:
        setQuizzes(removeEntityFromCollection(entity, quizzes) as Quiz[]);
        break;
      case EntityType.Slideshow:
        setSlideshows(
          removeEntityFromCollection(entity, slideshows) as Slideshow[]
        );
        break;
      case EntityType.Video:
        setVideos(removeEntityFromCollection(entity, videos) as Video[]);
        break;
    }
  };

  // anytime the query/category changes, fetchData()
  useEffect(() => {
    const fetchData = (): void => {
      if (query && query.length >= 0) {
        setLoading(true);

        fetchJSON(`${selectedEntity.url}?q=${query}`)
          .then((results) => setResults(results as Entity[]))
          .catch((error) => {
            setError(error.message);
            setResults([]);
          })
          .finally(() => setLoading(false));
      } else {
        setResults([]);
        setLoading(false);
      }
    };

    fetchData();
  }, [query, selectedEntity]);

  // only trigger the real query to update after:
  // - we've debounced the input
  // - at least MIN_CHARACTER_LIMIT have been entered
  useEffect(() => {
    let timer;

    if (debouncedQuery.length >= MIN_CHARACTER_INPUT) {
      timer = setTimeout(() => setQuery(debouncedQuery), 500);
    } else {
      setQuery('');
    }

    return () => clearTimeout(timer);
  }, [debouncedQuery, setQuery]);

  return (
    <>
      <div
        className={`related-search relative mb-10 ${
          results.length > 0 ? 'mod-related-results' : ''
        }`}
      >
        {error && <ErrorMessage text={error} />}

        <div className="flex items-start p-6">
          <div className="flex-1">
            <div className="relative mt-px">
              <EntitySearchInput
                query={debouncedQuery}
                onQueryChange={handleQueryChange}
                onClearResults={handleClearResults}
              />
              <div className="absolute top-0 right-0 pt-2 mr-10 mt-1">
                {loading && <Loading />}
              </div>
            </div>
          </div>
          <div className="flex ml-4 w-1/6">
            <EntitySelect
              entityMap={entityMap}
              selectedIndex={DEFAULT_ENTITY_INDEX}
              onSelectChange={handleEntitySelect}
            />
          </div>
        </div>
        <EntityResults
          category={selectedEntity.typename}
          results={results}
          onAddEntity={handleAddEntity}
          onClearResults={handleClearResults}
        />
      </div>

      <RelatedArticles articles={articles} onRemove={handleRemoveEntity} />
      <RelatedAudios audios={audios} onRemove={handleRemoveEntity} />
      <RelatedAuthors authors={authors} onRemove={handleRemoveEntity} />
      <RelatedDocuments documents={documents} onRemove={handleRemoveEntity} />
      <RelatedImages images={images} onRemove={handleRemoveEntity} />
      <RelatedKeyDates keyDates={keyDates} onRemove={handleRemoveEntity} />
      <RelatedKeywords keywords={keywords} onRemove={handleRemoveEntity} />
      <RelatedQuizzes quizzes={quizzes} onRemove={handleRemoveEntity} />
      <RelatedExhibits exhibits={exhibits} onRemove={handleRemoveEntity} />
      <RelatedSlideshows
        slideshows={slideshows}
        onRemove={handleRemoveEntity}
      />
      <RelatedVideos videos={videos} onRemove={handleRemoveEntity} />

      {keywords.map((keyword) => (
        <input
          key={keyword.id}
          type="hidden"
          name="keyword_ids[]"
          value={keyword.id}
        />
      ))}
      {articles.map((article) => (
        <input
          key={article.id}
          type="hidden"
          name="article_ids[]"
          value={article.id}
        />
      ))}
      {keyDates.map((keyDate) => (
        <input
          key={keyDate.id}
          type="hidden"
          name="key_date_ids[]"
          value={keyDate.id}
        />
      ))}
      {authors.map((author) => (
        <input
          key={author.id}
          type="hidden"
          name="author_ids[]"
          value={author.id}
        />
      ))}
      {quizzes.map((quiz) => (
        <input key={quiz.id} type="hidden" name="quiz_ids[]" value={quiz.id} />
      ))}
      {exhibits.map((exhibit) => (
        <input
          key={exhibit.id}
          type="hidden"
          name="exhibit_ids[]"
          value={exhibit.id}
        />
      ))}
      {slideshows.map((slideshow) => (
        <input
          key={slideshow.id}
          type="hidden"
          name="slideshow_ids[]"
          value={slideshow.id}
        />
      ))}
      {audios.map((audio) => (
        <input
          key={audio.id}
          type="hidden"
          name="audio_ids[]"
          value={audio.id}
        />
      ))}
      {documents.map((document) => (
        <input
          key={document.id}
          type="hidden"
          name="document_ids[]"
          value={document.id}
        />
      ))}
      {images.map((image) => (
        <input
          key={image.id}
          type="hidden"
          name="image_ids[]"
          value={image.id}
        />
      ))}
      {videos.map((video) => (
        <input
          key={video.id}
          type="hidden"
          name="video_ids[]"
          value={video.id}
        />
      ))}
    </>
  );
};

export default EntitySearch;
